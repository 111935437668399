
import { Component, Prop, Vue } from 'vue-property-decorator';
import { deletePaymentRecord, paymentRecordTableColumns } from '@/helpers/project/PaymentRecordHelper';
import { getTableLocale } from '@/helpers/LocaleHelper';
import { Button, ConfigProvider, Popconfirm, Table } from 'ant-design-vue';
import Project from '@/models/Project';

@Component({
    name: 'PaymentRecordsTableNew',
    components: {
        ConfigProvider,
        Button,
        Popconfirm,
        Table,
    },
})
export default class PaymentRecordsTableNew extends Vue {
    @Prop({ required: true }) private project!: Project;

    private get paymentRecordsTableData() {
        if (this.project == null || this.project.paymentRecords == null) {
            return [];
        }

        return this.project.paymentRecords.map((paymentRecord) => {
            return {
                id: paymentRecord.id,
                amount: paymentRecord.amount,
                currency: paymentRecord?.currency?.name,
                paymentDate: new Date(paymentRecord.paymentDate).getTime(),
                offerNumber: paymentRecord?.offer?.id,
            };
        });
    }

    private get columns() {
        return paymentRecordTableColumns();
    }

    private get locale() {
        return getTableLocale();
    }

    private async onDelete(paymentRecordId: string) {
        await deletePaymentRecord(paymentRecordId);
    }

    private onEdit(paymentRecordId: string) {
        this.$emit('onEdit', paymentRecordId);
    }
}
