
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Button, Tooltip } from 'ant-design-vue';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import PaymentRecordModal from '@/components/views/NewProjectView/Payments/PaymentRecordModal.vue';
import Project from '@/models/Project';

@Component({
    name: 'PaymentRecordHandler',
    components: { PaymentRecordModal, Tooltip, Button },
})
export default class PaymentRecordHandler extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ required: true }) private project!: Project;
    @Prop({ default: null }) private editingPaymentRecordId!: string | null;
    @Prop({ required: true }) private selectedOfferId!: string;
    @Prop({ required: true }) private isOrderState!: boolean;

    private isModalVisible = false;
    private isTooltipVisible = false;
    private get arePaymentsDisabled() {
        // return this.isOrderState || !this.selectedOfferId;
        return this.isOrderState;
    }
    // private get tooltipVisible() {
    //     return this.isOrderState; // Tooltip only works when isOrderState is true
    // }

    private onTooltipVisibilityChange(visible: boolean) {
        this.isTooltipVisible = visible;
    }

    private openPriceManipulationModal() {
        this.isModalVisible = true;
    }

    @Watch('editingPaymentRecordId')
    private onEditingPaymentRecordIdChange() {
        if (this.editingPaymentRecordId != null) {
            this.isModalVisible = true;
        }
    }
}
