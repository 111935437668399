
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import { Button, DatePicker, Divider, Form, InputNumber, Select } from 'ant-design-vue';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import { PaymentRecordFormFields } from '@/enums/components/ProjectNew/PaymentRecordFormFields';
import { getPaymentRecordsDecoratorRules, updateOrCreatePaymentRecord } from '@/helpers/project/PaymentRecordHelper';
import PaymentRecordRepository from '@/repositories/PaymentRecordRepository';
import CurrencyRepository from '@/repositories/CurrencyRepository';
import Currency from '@/models/Currency';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import Offer from '@/models/Offer';

@Component({
    name: 'PaymentRecordFormNew',
    components: {
        Form,
        FormItem: Form.Item,
        Divider,
        DatePicker,
        InputNumber,
        Select,
        SelectOption: Select.Option,
        Button,
    },
})
export default class PaymentRecordFormNew extends Vue {
    @Prop({ default: null }) private projectId!: string;
    @Prop({ default: false }) private isLoading!: boolean;
    @Prop({ default: null }) private paymentRecordId!: string | null;
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private selectedOfferId!: string;

    private paymentRecordFormFields = PaymentRecordFormFields;

    public get isProjectLocked() {
        if (this.project == null) {
            return false;
        }

        return this.project.state === ProjectStates.ORDER;
    }

    private get form() {
        return this.$form.createForm(this, {
            name: 'paymentRecordForm',
        } as IformCreateOption);
    }

    private get currencies() {
        return CurrencyRepository.getAll();
    }

    private get paymentRecord() {
        if (this.paymentRecordId == null) {
            return null;
        }

        return PaymentRecordRepository.getById(this.paymentRecordId);
    }

    private get decoratorRules() {
        return getPaymentRecordsDecoratorRules(this.paymentRecord, this.project);
    }

    private get locale() {
        return getDatepickerLocale();
    }

    private handleSubmit(event: Event) {
        event.preventDefault();

        // @ts-ignore
        this.form.validateFieldsAndScroll(async (err: Error, values: PaymentRecordTableEntry) => {
            if (err) {
                return Promise.reject();
            }

            this.$emit('update:isLoading', true);

            try {
                await updateOrCreatePaymentRecord(this.paymentRecordId, values, this.projectId, this.selectedOfferId);

                try {
                    Offer.orderAccountingOffer(this.selectedOfferId);
                } catch (e) {
                    console.warn('Expected error in orderAccountingOffer:', e);
                }
            } catch (e) {
                this.$emit('update:isLoading', false);
                return;
            }

            this.$emit('update:isLoading', false);
            this.$emit('cancel');
            return Promise.resolve();
        });
    }

    private async mounted() {
        await Currency.getAll();
    }
}
