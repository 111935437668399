
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import ProjectShellComponent from '@/components/views/NewProjectView/ProjectShellComponent.vue';
import PaymentRecordsTableNew from '@/components/views/NewProjectView/Payments/PaymentRecordsTableNew.vue';
import PaymentRecordHandler from '@/components/views/NewProjectView/Payments/PaymentRecordHandler.vue';
import PaymentOfferSelector from '@/components/views/NewProjectView/Payments/PaymentOfferSelector.vue';
import PaymentOfferSendButton from '@/components/views/NewProjectView/Payments/PaymentOfferSendButton.vue';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';

@Component({
    name: 'ProjectTabPayments',
    components: {
        PaymentRecordHandler,
        ProjectShellComponent,
        PaymentRecordsTableNew,
        PaymentOfferSelector,
        PaymentOfferSendButton,
    },
})
export default class ProjectTabPayments extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ required: true }) private project!: Project;
    private editingPaymentRecordId: null | string = null;
    @Prop({ default: [] }) private loadedKeys!: Set<ProjectTabValues>;
    @Prop({ required: true }) private lockEditing!: boolean;

    private selectedOfferId: string = '';

    private get payments() {
        return this.project.paymentRecords;
    }

    private get isOrderState() {
        return this.project.state === ProjectStates.ORDER;
    }

    private handleEditing(paymentRecordId: string) {
        this.editingPaymentRecordId = paymentRecordId;
    }

    private async created() {
        if (this.loadedKeys.has(ProjectTabValues.Payments)) {
            return;
        }
        this.$emit('update:isTabLoading', true);
        this.$emit('update:loadedKeys', new Set([...this.loadedKeys, ProjectTabValues.Payments]));
        this.$emit('update:isTabLoading', false);
    }
}
