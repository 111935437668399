import i18n from '@/i18n';
import { PaymentRecordTableEntry } from '@/interfaces/components/project/PaymentRecordTableEntry';
import PaymentRecord from '@/models/PaymentRecord';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { notification } from 'ant-design-vue';
import store from '@/store';
import { PaymentRecordFormFields } from '@/enums/components/ProjectNew/PaymentRecordFormFields';
import CurrencyRepository from '@/repositories/CurrencyRepository';
import Project from '@/models/Project';
import moment from 'moment';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { AxiosError } from 'axios';

export function paymentRecordTableColumns() {
    return [
        {
            title: i18n.t('Iznos'),
            dataIndex: 'amount',
            key: 'amount',
            scopedSlots: { customRender: 'amount' },
        },
        {
            title: i18n.t('Valuta'),
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: `${i18n.t('Datum uplate')}`,
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            scopedSlots: { customRender: 'date' },
            defaultSortOrder: 'descend',
            sorter: (a: PaymentRecordTableEntry, b: PaymentRecordTableEntry) => a.paymentDate - b.paymentDate,
        },
        {
            title: i18n.t('Offer number'),
            dataIndex: 'offerNumber',
            key: 'offerNumber',
        },
        // {
        //     title: `${i18n.t('Akcije')}`,
        //     key: 'action',
        //     scopedSlots: { customRender: 'action' },
        //     align: 'right',
        // },
    ];
}

export async function updateOrCreatePaymentRecord(
    editingPaymentRecordId: string | null,
    values: any,
    projectId: string,
    offerId: string
) {
    try {
        if (editingPaymentRecordId) {
            await PaymentRecord.updateExisting(editingPaymentRecordId, values);
            notification.success({
                message: i18n.t('Uplata je uspješno ažurirana!') as string,
                description: '',
            });
        } else {
            await PaymentRecord.createNew(
                {
                    ...values,
                    projectId,
                },
                offerId
            );
            notification.success({
                message: i18n.t('Uplata je uspješno stvorena!') as string,
                description: '',
            });
        }

        EventBus.$emit(EventBusEvents.updateProject);
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject(e);
    }

    return Promise.resolve();
}

export async function deletePaymentRecord(paymentRecordId: string) {
    await store.dispatch('projectLoadingState/updateIsProjectLoading', true);
    try {
        await PaymentRecord.deleteExisting(paymentRecordId);
    } catch (e) {
        await store.dispatch('projectLoadingState/updateIsProjectLoading', false);
        notification.error({
            message: i18n.t('Dogodila se greška') as string,
            description: (e as Error).message,
        });
        return;
    }

    EventBus.$emit(EventBusEvents.fetchProjectFromRepository);
    notification.success({
        message: i18n.t('Uplata je uspješno obrisana') as string,
        description: '',
    });

    await store.dispatch('projectLoadingState/updateIsProjectLoading', false);
    EventBus.$emit(EventBusEvents.updateProject);
}

/**
 * Generates the decorator rules for the payment record form
 * @param paymentRecord - The payment record entity if it exists
 * @param project - The active project
 * @return An object of decorator rules for each field in the form
 */
export function getPaymentRecordsDecoratorRules(paymentRecord: PaymentRecord | null, project: Project) {
    return {
        [PaymentRecordFormFields.AMOUNT]: [
            PaymentRecordFormFields.AMOUNT,
            {
                initialValue: paymentRecord ? paymentRecord.amount : 0,
                rules: [
                    {
                        required: true,
                        message: i18n.t('Iznos je obavezan!'),
                    },
                    {
                        validator: (_: any, value: number) => {
                            if (value > 0) {
                                return Promise.resolve();
                            }
                            return Promise.reject(i18n.t('Price must be greater than 0'));
                        },
                    },
                ],
            },
        ],
        [PaymentRecordFormFields.PAYMENT_PERCENTAGE]: [
            PaymentRecordFormFields.PAYMENT_PERCENTAGE,
            {
                initialValue: null,
                rules: [
                    {
                        required: false,
                    },
                    {
                        validator: (_: any, value: number) => {
                            if (value > 0 || value == null) {
                                return Promise.resolve();
                            }
                            return Promise.reject(i18n.t('Percentage must be greater than 0'));
                        },
                    },
                ],
            },
        ],
        [PaymentRecordFormFields.CURRENCY]: [
            PaymentRecordFormFields.CURRENCY,
            {
                initialValue: paymentRecord ? paymentRecord.currency.id : defaultCurrency(project),
                rules: [{ required: false }],
            },
        ],
        [PaymentRecordFormFields.PAYMENT_DATE]: [
            PaymentRecordFormFields.PAYMENT_DATE,
            {
                initialValue: paymentRecord ? moment(paymentRecord.paymentDate) : moment(),
                rules: [
                    {
                        type: 'object',
                        required: false,
                        whitespace: true,
                    },
                ],
            },
        ],
    };
}

export function defaultCurrency(project: Project) {
    if (project == null || project.projectClient == null || project.projectClient.currency == null) {
        const euroCurrency = CurrencyRepository.getBySymbol('€');
        if (euroCurrency == null) {
            return '';
        }

        return euroCurrency.id;
    }

    return project.projectClient.currency.id;
}
